import consumer from './consumer'

const userId = document.body.dataset.userId
consumer.subscriptions.create(
  { channel: 'PlanChannel', user_id: userId },
  {
    connected() {},

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      if (data.action === 'redirect' && data.url && window.location.pathname === '/plans/change_plan_completed') {
        window.location.href = data.url
      }
    }
  }
)
